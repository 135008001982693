import React, { Component } from "react";
import Asset from "./asset";
import styles from "./about-awards-press.module.scss";
import theme from "../styles/theme.module.scss";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function AboutClients(props) {
  return (
    <div
      className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT7} ${theme.pB3} ${theme.borderBottom}`}
    >
      <h2 className={`${theme.h5_founders} ${theme.gdmain} ${theme.gmmain} ${theme.mB5}`}>
        Awards and Press
      </h2>
      <PortableText
        className={`${theme.portableText} ${theme.h2_tiempos} ${theme.gdmain} ${theme.gmmain}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawAwardsPressCopyPortable}
        serializers={serializers}
      />
      <div
        className={`${styles.list} ${theme.gdmain} ${theme.gmmain} ${theme.fdcont} ${theme.mT3}`}
      >
        <div className={`${styles.unit} ${theme.fd3}`}>
          <h3 className={`${theme.h5_founders} ${theme.mB8}`}>Awards</h3>
          <ul>
            {props.awards &&
              props.awards.map((node, index) => (
                <li className={`${styles.listItem}`} key={index}>
                  {node}
                </li>
              ))}
          </ul>
        </div>
        <div className={`${styles.unit} ${theme.fd3}`}>
          <h3 className={`${theme.h5_founders} ${theme.mB8}`}>Print Press</h3>
          <ul>
            {props.printPress &&
              props.printPress.map((node, index) => (
                <li className={`${styles.listItem}`} key={index}>
                  {node}
                </li>
              ))}
          </ul>
        </div>
        <div className={`${styles.unit} ${theme.fd3}`}>
          <h3 className={`${theme.h5_founders} ${theme.mB8}`}>Online Press</h3>
          <ul>
            {props.onlinePress &&
              props.onlinePress.map((node, index) => (
                <li className={`${styles.listItem}`} key={index}>
                  {node}
                </li>
              ))}
          </ul>
        </div>
        <div className={`${styles.unit} ${theme.fd3}`}>
          <h3 className={`${theme.h5_founders} ${theme.mB8}`}>Books</h3>
          <ul>
            {props.books &&
              props.books.map((node, index) => (
                <li className={`${styles.listItem}`} key={index}>
                  {node}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AboutClients;
