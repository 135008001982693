import React, { Component } from "react";
import theme from "../styles/theme.module.scss";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function AboutIntro(props) {
  return (
    <div
      className={`${theme.gdcont} ${theme.gmcont} ${theme.mT4} ${theme.pB3} ${theme.borderBottom}`}
    >
      <p className={`${theme.huge} ${theme.gds1} ${theme.gde9} ${theme.mB3} ${theme.gmmain}`}>
        {props.topHeadline}
      </p>
      <h2
        className={`${theme.h5_founders} ${theme.gds1} ${theme.gde4} ${theme.gmmain} ${theme.mB6}`}
      >
        About Us
      </h2>
      <PortableText
        className={`${theme.portableText} ${theme.h2_tiempos} ${theme.gds5} ${theme.gde12} ${theme.gmmain}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawEntryCopyPortable}
        serializers={serializers}
      />
    </div>
  );
}

export default AboutIntro;
