import React, { useEffect } from 'react';
import crypto from 'crypto'
import { navigate } from 'gatsby'
import IndexPage from '../pages/index'
import ContactPage from '../pages/contact/index'
import AboutPage from '../pages/about'
import WorkPage from '../pages/work'
import ProjectPage from '../templates/project'
import PhilosophyPage from '../pages/philosophy'
import OffTwoMinds from '../pages/oftwomindsmx'

const PreviewTemplate = (props) => {
  let invalid = false

  const isExpired = timestamp => {
    const now = new Date().getTime()
    const diff = now - timestamp
    return diff >= 60 * 60 * 1000
  }
  const checksum = (message = '', hmac) => {
    const token = props.token
    const hash = crypto.createHmac('sha256', token).update(message).digest('hex')
    return hash === hmac
  }
  const urlSearchParams = new URLSearchParams(props.location.search)
  const previewId = urlSearchParams.get('pageId')
  const slug = urlSearchParams.get('slug')
  const isDraft = urlSearchParams.get('isDraft')
  const type = urlSearchParams.get('type')
  const timestamp = urlSearchParams.get('timestamp')
  const hmac = urlSearchParams.get('hmac')
  const pageId = isDraft ? previewId.replace('drafts.', '') : previewId
  const params = `type=${type}&pageId=${previewId}${slug ? `&slug=${slug}` : ''}&timestamp=${timestamp}${isDraft ? '&isDraft=true' : ''}`

  if (!timestamp || !hmac || isExpired(timestamp) || !checksum(params, hmac)) {
    invalid = true
  }

  let PreviewComponent = null


  switch (type) {
    case 'homePage': {
      PreviewComponent = (<IndexPage data={props.data} />)
      break
    }
    case "project": {
      const projects = props.data.allProjects.edges
      const currentProject = projects.find(({ node }) => node.slug.current === slug)
      const pageData = currentProject ? { ...props.data, project: currentProject.node } : props.data
      PreviewComponent = currentProject ? (<ProjectPage data={pageData} />) : (<WorkPage data={pageData} />)

      break
    }
    case "about": {
      PreviewComponent = (<AboutPage data={props.data} />)
      break
    }
    case "contact": {
      PreviewComponent = (<ContactPage data={props.data} />)
      break
    }
    case "philosophy": {
      PreviewComponent = (<PhilosophyPage data={props.data} />)
      break
    }
    case "offTwoMinds": {
      PreviewComponent = (<OffTwoMinds data={props.data} />)
      break
    }
    default:
      invalid = true
  }

  useEffect(() => {
    if (invalid) {
      navigate('/')
    }
  }, [])
  return (
    <>
      {PreviewComponent}
    </>
  );
}

export default PreviewTemplate;
