import React, { useRef, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { debounce } from '../lib/helpers';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import Overlay from '../components/home-overlay';
import ProjectsGrid from '../components/projects-grid';
import ProjectsList from '../components/home-projects-list';
import Press from '../components/press';
import Intro from '../components/home-intro';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      mentorshipFormToggle
      socialImage {
        asset {
          url
        }
      }
    }
    homepage: sanityHomePage {
      ...SanityHomeFragment
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const homepage = (data || {}).homepage;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const projectsGridNodes = data.site.projectsGrid;
  const projectListNodes = data.homepage.ProjectsList;
  const pressNodes = data.homepage.pressEntries;
  const asofSettings = data.site.asofSettings;

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(currentScrollPos < window.innerHeight);
    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchmove', handleScroll, { passive: true });

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };
  }, [prevScrollPos, setVisible === true, visible, handleScroll]);

  return (
    <Layout visible={visible}>
      <SEO
        title='RoAndCo | Ro Agency and Branding'
        description={site.description}
        keywords={site.keywords}
        isHomePage
        ogImage={site?.socialImage?.asset?.url}
      />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        <Overlay />
        {projectsGridNodes && <ProjectsGrid nodes={projectsGridNodes} />}
        <Intro copy={data.homepage.copy} />
        {projectListNodes && <ProjectsList nodes={projectListNodes} />}
        {pressNodes && <Press nodes={pressNodes} />}
      </Container>
    </Layout>
  );
};

export default IndexPage;
