import React, { Component } from "react";
import styles from "./about-services.module.scss";
import theme from "../styles/theme.module.scss";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function AboutClients(props) {
  return (
    <div
      className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT7} ${theme.pB3} ${theme.borderBottom}`}
    >
      <h2 className={`${theme.h5_founders} ${theme.gdmain} ${theme.mB5} ${theme.gmmain}`}>
        Services
      </h2>
      <ul className={`${styles.list} ${theme.gdmain} ${theme.gmmain}`}>
        {props.servicesList &&
          props.servicesList.map((node, index) => (
            <li className={`${styles.listItem} ${theme.h2_tiempos}`} key={index}>
              {node}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default AboutClients;
