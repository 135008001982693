import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Intro from "../components/about-intro";
import Services from "../components/about-services";
import TeamDesktop from "../components/about-team-desktop";
import TeamMobile from "../components/about-team-mobile";
import Clients from "../components/about-clients";
import Rj from "../components/about-rj";
import Sustainability from "../components/about-sustainability";
import AwardsPress from "../components/about-awards-press";
import Press from "../components/press";
import SEO from "../components/seo";
import CoCreateContact from "../components/co-create-contact"
import { useBreakpoint } from "gatsby-plugin-breakpoints";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      socialImage {
        asset {
          url
        }
      }
    }
    about: sanityAbout {
      ...SanityAboutFragment
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const about = (data || {}).about;
  const teamNodes = about.teamMembers;
  const pressNodes = about.pressEntries;
  const breakpoints = useBreakpoint();

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="About" description={site.description} keywords={site.keywords} ogImage={site?.socialImage?.asset?.url}/>
      <Container>
        <h1 hidden>About</h1>
        <Intro {...about} />
        <Services {...about} />
        {/* {!breakpoints.sm && (
          <>
            <TeamDesktop teamNodes={teamNodes} />
          </>
        )}
        {breakpoints.sm && (
          <>
            <TeamMobile teamNodes={teamNodes} />
          </>
        )} */}
        <Clients {...about} />
        <Rj {...about} />
        <AwardsPress {...about} />
        <Sustainability {...about} />
        <Press nodes={pressNodes} />
        <CoCreateContact
          title={about.footerHeadline}
          columns={about.footerColumns}
        />
      </Container>
    </Layout>
  );
};

export default AboutPage;
