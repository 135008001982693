import React from "react";
import styles from "./about-team.module.scss";
import theme from "../styles/theme.module.scss";
import TeamMember from "./about-team-member.js";

function AboutIntro(props) {
  return (
    <div
      className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT7} ${theme.pB7} ${theme.borderBottom}`}
    >
      <h2 className={`${theme.h5_founders} ${theme.gdmain} ${theme.mB3} ${theme.gmmain}`}>
        Our Team
      </h2>
      <ul className={`${styles.list} ${theme.gdmain} ${theme.gmmain} ${theme.fdcont}`}>
        {props.teamNodes &&
          props.teamNodes.map((node, index) => <TeamMember key={index} {...node} />)}
      </ul>
    </div>
  );
}

export default AboutIntro;
