import React, { Component } from "react";
import Asset from "./asset";
import theme from "../styles/theme.module.scss";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function AboutClients(props) {
  return (
    <div className={`${theme.gdcont} ${theme.gmcont} ${theme.mT7} ${theme.pB3}`}>
      <h2 className={`${theme.h5_founders} ${theme.gdmain} ${theme.gmmain} ${theme.mB5}`}>
        {props.sustainabilityHeadline}
      </h2>
      <PortableText
        className={`${theme.portableText} ${theme.h2_tiempos} ${theme.gmmain} ${theme.gdmain}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawSustainabilityCopyPortable}
        serializers={serializers}
      />
    </div>
  );
}

export default AboutClients;
