import React, { Component } from "react";
import Asset from "./asset";
import styles from "./about-rj.module.scss";
import theme from "../styles/theme.module.scss";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function AboutClients(props) {
  return (
    <div
      className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT7} ${theme.pB3} ${theme.borderBottom}`}
    >
      <h2 className={`${theme.h5_founders} ${theme.gdmain} ${theme.gmmain} ${theme.mB5}`}>
        Romance Journal
      </h2>
      <PortableText
        className={`${theme.portableText} ${theme.h2_tiempos} ${theme.gmmain} ${theme.gdmain}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawRjCopyPortable}
        serializers={serializers}
      />
      <div
        className={`${styles.image} ${theme.gdmain} ${theme.gmmain} ${theme.fdcont} ${theme.fdcenter} ${theme.mT3} ${theme.mB3}`}
      >
        <div className={`${styles.entry} ${theme.fd9}`}>
          <Asset asset={props.rjAssets[0]} maxWidth="800" />
        </div>
      </div>
    </div>
  );
}

export default AboutClients;
